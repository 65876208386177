<template>
    <v-row>
        <v-col cols="4">
            <Form/>
        </v-col>
        <v-col cols="8">
            <List/>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data(){
        return{}
    },
    components: {
        Form: () => import("@/components/agent/Form.vue"),
        List: () => import("@/components/agent/List.vue")
    }
}
</script>